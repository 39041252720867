<template>
    <v-app-bar
            app
            :clipped-left="$vuetify.breakpoint.xsAndUp"
            color="#7a1705"
    >
        <div class="bar"></div>
        <div class="bottombar"></div>
        <div class="subbar elevation-3"></div>
        <div class="logo">
            <img src="@/assets/trident-tab.gif" height="73" width="64" alt=" ">
        </div>
        <div class="iu-title text-h5 white--text font-weight-medium">
            Indiana University
        </div>
        <div class="project-title text-h4 white--text font-weight-bold">
            CMG-SCA <span class="text-subtitle-1">Medical Genomics Archive</span>
        </div>
<!--    <header class="institution-header" id="branding-bar-v1" itemscope="itemscope" itemtype="http://schema.org/CollegeOrUniversity">-->
<!--        <div class="bar">-->
<!--            <div class="container">-->
<!--                <div class="unit whole">-->
<!--                    <h1 class="campus mr-auto">-->
<!--                        <a title="Indiana University">-->
<!--                            <img src="@/assets/trident-tab.gif" height="73" width="64" alt=" ">-->
<!--                            <span class="show-on-desktop" itemprop="name sourceOrganization provider">Indiana University</span>-->
<!--                            <span class="show-on-tablet" itemprop="name sourceOrganization provider">Indiana University</span>-->
<!--                            <span class="show-on-mobile" itemprop="name sourceOrganization provider">Indiana University</span>-->
<!--                        </a>-->
<!--                    </h1>-->
<!--&lt;!&ndash;                    <b-button-group class="header-buttons">&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-button class="btn-success" :to="'/signin?redirect='+path" v-if="$store.state.auth.uid === ''">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="glyphicon glyphicon-log-in"></span> <span class=""> Log in</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-button>&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-button class="btn-warning" @click="logout" v-if="$store.state.auth.uid !== ''">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="glyphicon glyphicon-log-in"></span> <span class=""> Log out</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-button>&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-button class="btn-info" v-b-modal.comment-modal>&ndash;&gt;-->
<!--&lt;!&ndash;                            <font-awesome-icon icon="envelope"></font-awesome-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </b-button-group>&ndash;&gt;-->
<!--                    &lt;!&ndash;<a class="btn btn-warning btn-sm btn-access">&ndash;&gt;-->
<!--                    &lt;!&ndash;<span class="glyphicon glyphicon-log-out"></span> <span class=""> Log out</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;</a>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="bar subheader">-->
<!--            <h3 class="site-title clickable" @click="goHome">CMG-SCA</h3>-->
<!--&lt;!&ndash;            <div class="pull-right text-right greeting" v-if="$store.state.auth.uid"><h6>Welcome <b>{{$store.state.auth.uid}}</b> <span v-if="$store.state.auth.role === 'admin'"> (admin)</span></h6></div>&ndash;&gt;-->
<!--        </div>-->
<!--    </header>-->
    </v-app-bar>
</template>

<script>

    import { mapActions } from 'vuex';

    export default {
        name: "Header",
        methods: {
            ...mapActions([
                'logout'
            ]),
            goHome: function() {
                this.$router.push('/');
            }
        },
        computed: {
            path: function() {
                console.log("path changed!");
                console.log(this.$route.path);
                console.log(this.$router.history.current.path);
                return this.$router.history.current.path;
            }
        }
    }
</script>

<style scoped>
    .header-buttons {
        position: absolute;
        right: 0px;
    }


    .logo {
        position: absolute;
        top: 6px;
        left: 50px;
    }

    .bar {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 1px;
        display: block;
        width: 100%;
        border-top: 6px solid #990000;
    }

    .iu-title {
        position: absolute;
        top: 15px;
        left: 120px;
    }

    .project-title {
        position: absolute;
        top: 52px;
        left: 120px;
    }

    .bottombar {
        position: absolute;
        top: 48px;
        left: 0px;
        height: 1px;
        display: block;
        width: 100%;
        border-top: 6px solid #990000;
    }

    .subbar {
        position: absolute;
        top: 54px;
        left: 0px;
        height: 36px;
        display: block;
        width: 100%;
        background-color: gray;
    }

    .v-toolbar {
        height: 48px !important;
    }

    .greeting {
        margin-top: 10px;
        margin-right: 5px;
    }
</style>
