<template>
  <v-app>
    <Drawer @navChange="updateActive"></Drawer>
    <Header role="user"></Header>

    <v-container fluid class="main mt-6 mb-4 ml-2 mr-1">
      <v-main class="mt-4">
        <div class="text-h4 font-weight-light">
          <v-icon large class="pb-2 ml-1">{{ active_page.action }}</v-icon>
          {{ active_page.title }}
        </div>
        <v-divider class="mb-3"></v-divider>
        <transition name="fade">
          <router-view class="mt-4"></router-view>
        </transition>
      </v-main>
    </v-container>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Drawer from '../components/Drawer'

export default {
  components: {
    Header,
    Footer,
    Drawer,
  },

  data: () => ({
    active_page: {
      action: '',
      title: '',
    },
  }),
  methods: {
    updateActive: function (navItem) {
      this.active_page.action = navItem.action
      this.active_page.title = navItem.title
    },
  },
}
</script>
