<template>
  <div>
    <v-row justify="start" align="center" no-gutters>
      <v-col cols="11" :class="descriptionClass">
        <markdown-it-vue
          class="md-body"
          :content="String(visibleDescription)"
        />
      </v-col>
      <v-col
        v-if="longDescription && !expandedDescription && expandable"
        cols="1"
        class="pl-1"
        @click="toggleDescription"
      >
        <v-icon x-large color="blue darken-2">mdi-plus-circle</v-icon>
      </v-col>
      <v-col
        v-if="longDescription && expandedDescription && expandable"
        class="pl-1"
        cols="1"
        @click="toggleDescription"
      >
        <v-icon x-large color="blue darken-2">mdi-minus-circle</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

// accept markdown
// automatically expand as needed.

export default {
  name: 'ExpandableDescription',
  components: {
    // VueMarkdown,
    MarkdownItVue,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedDescription: this.expanded,
      breakpoint: 50,
    }
  },
  computed: {
    admin_only() {
      return this.$store.state.auth.role === 'admin'
    },
    descriptionClass() {
      if (this.expandedDescription) {
        return 'expanded'
      } else {
        return 'collapsed'
      }
    },

    longDescription() {
      if (this.description) {
        const words = this.description.split(/\s+/)
        if (words.length > this.breakpoint) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    // decide how much of the description to display
    visibleDescription() {
      const words = this.description.split(/\s+/)
      if (words.length < this.breakpoint || this.expandedDescription) {
        return this.description.replace('<!--more-->', '')
      } else {
        // Check to see if the description has a `<!--more-->` tag
        // Used to manually delineate between excerpt
        // and the rest of the content
        // Idea adapted from:
        // https://content.nuxtjs.org/writing/
        const position = this.description.search('<!--more-->')
        if (position) {
          const parts = this.description.split('<!--more-->')
          return parts[0] + '...'
        } else {
          // need to keep all of the whitespace as is
          // otherwise markdown formatting gets thrown off
          // going to try:
          // find the next word
          // split on that
          // check for number of occurences in truncated
          // reassemble
          const nextWord = words[this.breakpoint + 1]
          const unformatted = words.slice(0, this.breakpoint + 1)
          const blurb = unformatted.join(' ')
          const subsections = blurb.split(nextWord)
          const sections = this.description.split(nextWord)
          let truncated = sections.slice(0, subsections.length)
          truncated = truncated.join(nextWord)
          truncated += ' ...'

          // console.log(truncated);
          return truncated
        }
      }
    },
  },
  methods: {
    toggleDescription() {
      if (this.expandedDescription) {
        this.expandedDescription = false
      } else {
        this.expandedDescription = true
      }
      // console.log("expandedDescription", this.expandedDescription);
    },
  },
}
</script>

<style>
.collapsed {
  /* --max-lines: 5;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden; */
}

.expanded {
}
</style>
