const exports = {
  mode: 'production',
  api: 'https://cmg.sca.iu.edu/api',
  download: 'https://cmg-data.sca.iu.edu',
  iucas_url: 'https://idp.login.iu.edu/idp/profile/cas/login',
  cas_return: 'https://cmg.sca.iu.edu/signin',
  default_redirect: '/signin',
  analytics_id: 'UA-117966188-8',
  warning_banner: {
    // message: "The Scholarly Data Archive (SDA) is currently experiencing difficulties and some features of CMG may not work.",
    message: "The carbonate cluster is currently undergoing unscheduled maintenance. Data access via the CMG-SCA portal is affected by this outage.",
    show: false
  },
  paths: {
    download_dir: '/N/scratch/cmguser/production/download'
  }
};

export default exports;
