<template>
  <div class="about max-w-screen-lg mx-auto">
    <v-card class="mx-auto" max-width="500">
      <v-img src="../assets/cmg_logo.png" class="elevation-4 logo" />
    </v-card>
    <v-container class="elevation-12 ma-6">
      <span v-if="admin_only" class="float-right">
        <v-btn @click="editAbout" color="warning" dark> Edit </v-btn>
      </span>
      <v-card-text class="text--primary">
        <expandable-description
          :description="content"
          :expanded="true"
          :expandable="false"
        ></expandable-description>
      </v-card-text>
    </v-container>

    <v-dialog v-if="admin_only" v-model="dialog">
      <v-card>
        <v-container>
          <div class="float-right">
            <slot name="close">
              <!-- <v-btn href="#" color="primary" @click="dialog = false">x</v-btn> -->
            </slot>
          </div>
          <v-form v-if="dialog" ref="form" @submit="onSubmit">
            <v-textarea
              v-model="updates"
              required
              auto-grow
              label="About Markdown Content"
            ></v-textarea>
            <v-btn type="submit" color="primary" class="mr-2">Save</v-btn>
            <v-btn @click="checkDiscard" type="reset" color="warning"
              >Discard</v-btn
            >
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm" max-width="400px" @keydown.esc="cancel">
      <ConfirmDialog
        @confirm="onReset"
        @cancel="confirm = false"
        title="Discard Changes?"
        message="You will lose any unsaved changes"
      >
      </ConfirmDialog>
    </v-dialog>
  </div>
</template>

<script>
import ExpandableDescription from '@/components/ExpandableDescription.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'About',
  components: {
    ExpandableDescription,
    ConfirmDialog,
  },
  computed: {
    admin_only() {
      const position = this.$store.state.auth.roles.indexOf('god')
      return position !== -1
    },
  },
  data() {
    return {
      confirm: false,
      dialog: false,
      // disabling binding for form
      // so content is not changing behind the modal
      // this was determined to be distracting while editing
      updates: '',
      content:
        'CMG-SCA is a joint project between the The Center for Medical Genomics (CMG) at Indiana University School of Medicine and the Indiana University Scalable Compute Archive (SCA) group within Research Technologies.',
    }
  },
  created() {
    this.getAbout()
    // console.log('Admin?', this.admin_only, this.$store.state.auth.roles)
  },

  methods: {
    getAbout() {
      this.$http
        .get(this.$config.api + '/content/about')
        .then((res) => {
          // console.log("About response from server:", res.data);
          this.content = res.data
          this.updates = JSON.parse(JSON.stringify(this.content))
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('snackbar/showMessage', {
            content: err,
            color: 'error',
          })
        })
    },
    checkDiscard() {
      console.log('Confirm discard okay...')
      this.confirm = true
    },

    editAbout() {
      console.log('editAbout called')
      // break binding
      this.updates = JSON.parse(JSON.stringify(this.content))
      // this.updates = Object.assign("", this.content);
      this.dialog = true
    },
    onSubmit(e) {
      console.log('onSubmit function called')

      // this.content = Object.assign("", this.updates);
      this.content = JSON.parse(JSON.stringify(this.updates))
      // console.log("value of content", this.content);
      // console.log("value of updates", this.updates);

      this.$http
        .patch(this.$config.api + '/content/about', {
          details: this.content,
        })
        .then((res) => {
          this.$emit('editComplete')
          this.dialog = false
          console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('snackbar/showMessage', {
            content: err,
            color: 'error',
          })
        })
      e.preventDefault()
    },
    onReset() {
      this.dialog = false
      this.confirm = false
      console.log('Resetting')
    },
  },
  // watch: {
  //   updates: function () {
  //     console.log("Updates changed", this.updates);
  //   },
  // },
}
</script>

<style scoped>
.about {
  font-size: 1rem;
}
.max-w-screen-lg {
  max-width: 1024px;
}
</style>
