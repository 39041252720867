<template>
    <v-app>
    <Header></Header>
        <v-container
                fluid
                class="main mt-6 mb-4 ml-2 mr-1">
            <v-main class="mt-4">
                <router-view></router-view>
            </v-main>
        </v-container>
    <Footer></Footer>
    </v-app>
</template>

<script>

  import Header from '../components/Header';
  import Footer from '../components/Footer';
  export default {
    components: {Header, Footer}
  }
</script>

<style>

</style>
