import Vue from 'vue'
import VueRouter from 'vue-router'
import Signin from '../views/Signin.vue'
import Signout from '../views/Signout.vue'
import About from '../views/About.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: {
      simple_layout: true,
    },
  },
  {
    path: '/signout',
    name: 'Signout',
    component: Signout,
    meta: {
      simple_layout: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: false,
      is_admin: false,
      layout: 'header-layout',
    },
  },
  {
    path: '/runs/:name/:product?',
    name: 'RunDetails',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/RunDetails.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
      layout: 'detail-layout',
    },
  },
  {
    path: '/runs',
    name: 'Runs',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Runs.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  { path: '/', redirect: { name: 'Projects' }},
  {
    path: '/projects',
    name: 'Projects',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/Projects.vue'),
    meta: {
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/Sessions.vue'),
    meta: {
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: '/tracks',
    name: 'Tracks',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/Tracks.vue'),
    meta: {
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: '/projects/:name',
    name: 'ProjectDetails',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/ProjectDetails.vue'),
    meta: {
      requiresAuth: true,
      is_admin: false,
      layout: 'detail-layout',
    },
  },
  // {
  //   path: '/downloads',
  //   name: 'Downloads',
  //   component: () =>
  //     import(/* webpackChunkName: "user" */ '../views/Downloads.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     is_admin: false,
  //   },
  // },
  {
    path: '/conversions',
    name: 'Conversions',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Conversions.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/conversions/:id',
    name: 'ConversionDetails',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/ConversionDetails.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
      layout: 'detail-layout',
    },
  },
  {
    path: '/dataproducts',
    name: 'DataProducts',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/DataProducts.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/datauploader',
    name: 'DataUploader',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/DataUploader.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Groups.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true,
      is_admin: false,
      layout: 'header-layout',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Users.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/clean',
    name: 'Clean',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Clean.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Stats.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/viewer/:id',
    name: 'SessionViewer',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/SessionViewer.vue'),
    meta: {
      requiresAuth: false,
      is_admin: false,
      layout: 'simple-layout',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  console.log('Router layout lookup:', from, next)
  console.log('To', to)
  // TODO: switch to using a layout parameter in meta instead of boolean
  if (
    to.matched.some((record) =>
      Object.prototype.hasOwnProperty.call(record.meta, 'layout')
    )
  ) {
    const hasLayout = to.matched.filter((record) =>
      Object.prototype.hasOwnProperty.call(record.meta, 'layout')
    )
    // go with first matched route
    console.log('Route with layout specified: ', hasLayout[0].meta.layout)
    store.commit('SET_LAYOUT', hasLayout[0].meta.layout)
  } else if (to.matched.some((record) => record.meta.simple_layout)) {
    store.commit('SET_LAYOUT', 'simple-layout')
  } else {
    store.commit('SET_LAYOUT', 'app-layout')
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let role = localStorage.getItem('role')
    let jwt_exp = localStorage.getItem('jwt_exp') || Date.now() / 1000
    let now = Date.now() / 1000 + 600

    console.log('In router, role is set to', role)
    console.log('In router, jwt_exp is set to', jwt_exp)
    console.log('In router, jwt expiration is ', now > jwt_exp)
    // let encoded = encodeURIComponent(to.path)
    // console.log('Encoded result is', encoded)
    if (now > jwt_exp) {
      return next({
        path: '/signin',
        query: { redirect: to.path },
      })
    }
    if (typeof role === 'undefined' || role === 'undefined' || !role) {
      return next({
        path: '/signin',
        query: { redirect: to.path },
        // this doesn't work
        // path: '/signin&redirect=' + encoded,
        // also doesn't work:
        // redirect: encoded,
      })
    } else {
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (store.getters.hasRole('admin')) {
          return next()
        } else {
          return next(false)
        }
      } else {
        return next()
      }
    }
  } else {
    return next()
  }
})

export default router
