import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

///////CONFIG/////
import config from './config'
Vue.prototype.$config = config

import genomes from '@/configs/genomes';
Vue.prototype.$genomes = genomes


//////ANALYTICS////
import VueAnalytics from 'vue-ua'

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'CMG Portal',
  // [Required] The version of your app.
  appVersion: '1.0',
  // [Required] Your Google Analytics tracking ID.
  trackingId: config.analytics_id,
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,

  trackPage: true,

  // // Global Dimensions and Metrics can optionally be specified.
  // globalDimensions: [
  //     { dimension: 1, value: 'FirstDimension' },
  //     { dimension: 2, value: 'SecondDimension' }
  //     // Because websites are only 2D, obviously. WebGL? What's that?
  // ],
  //
  // globalMetrics: [
  //     { metric: 1, value: 'MyMetricValue' },
  //     { metric: 2, value: 'AnotherMetricValue' }
  // ]
});


//////AXIOS///////
import Axios from 'axios'
Vue.prototype.$http = Axios

//Gravatar
import Gravatar from 'vue-gravatar'
Vue.component('v-gravatar', Gravatar)

Axios.interceptors.request.use(
  (config) => {
    let token = store.state.auth.jwt

    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

////Treeview/////
import TreeView from 'vue-json-tree-view'
Vue.use(TreeView)

/////MOMENT.JS//////
Vue.use(require('vue-moment'))

//clipboard
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

// usage: {{ file.size | prettyBytes }}
Vue.filter('prettyBytes', function (num) {
  // adapted from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  var exponent
  var unit
  var neg = num < 0
  var units = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1024)),
    units.length - 1
  )
  num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
})

Vue.filter('gigaBytes', function (bytes) {
  // see also ui/src/components/FileSize.vue
  if (typeof bytes !== 'number' || isNaN(bytes)) {
    throw new TypeError('Expected a number')
  }

  var marker = 1024 // Change to 1000 if required
  var decimal = 2
  // var kiloBytes = marker // One Kilobyte is 1024 bytes
  // var megaBytes = marker * marker // One MB is 1024 KB
  var gigaBytes = marker * marker * marker // One GB is 1024 MB
  // var teraBytes = marker * marker * marker * marker // One TB is 1024 GB

  // // return bytes if less than a KB
  // if(bytes < kiloBytes) return bytes + " Bytes";
  // // return KB if less than a MB
  // else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
  // // return MB if less than a GB
  // else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
  // // return GB if less than a TB
  return (bytes / gigaBytes).toFixed(decimal) + ' GiB'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
