var exports =  {
  "human": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Human.png",
      "genomes": [
      "hg19",
      "hg38",
      "t2t-chm13-v1.1"
    ]
  },
  "chimp": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Chimp.png",
      "genomes": [
      "panTro6",
      "panTro5",
      "panTro4"
    ]
  },
  "gorilla": {
    "image": "https://vizhub.wustl.edu/public/gorGor3/Gorilla.png",
      "genomes": [
      "gorGor4",
      "gorGor3"
    ]
  },
  "gibbon": {
    "image": "https://vizhub.wustl.edu/public/nomLeu3/Gibbon.png",
      "genomes": [
      "nomLeu3"
    ]
  },
  "baboon": {
    "image": "https://vizhub.wustl.edu/public/papAnu2/Baboon.png",
      "genomes": [
      "papAnu2"
    ]
  },
  "rhesus": {
    "image": "https://vizhub.wustl.edu/public/rheMac8/Rhesus_macaque.png",
      "genomes": [
      "rheMac8",
      "rheMac3",
      "rheMac2"
    ]
  },
  "marmoset": {
    "image": "https://vizhub.wustl.edu/public/calJac3/Marmoset.png",
      "genomes": [
      "calJac3"
    ]
  },
  "cow": {
    "image": "https://vizhub.wustl.edu/public/bosTau8/Cow.png",
      "genomes": [
      "bosTau8"
    ]
  },
  "rabbit": {
    "image": "https://vizhub.wustl.edu/public/oryCun2/rabbit.png",
      "genomes": [
      "oryCun2"
    ]
  },
  "dog": {
    "image": "https://vizhub.wustl.edu/public/canFam3/dog.png",
      "genomes": [
      "canFam3",
      "canFam2"
    ]
  },
  "mouse": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Mouse.png",
      "genomes": [
      "mm39",
      "mm10",
      "mm9"
    ]
  },
  "rat": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Rat.png",
      "genomes": [
      "rn6",
      "rn4"
    ]
  },
  "opossum": {
    "image": "https://vizhub.wustl.edu/public/monDom5/opossum.png",
      "genomes": [
      "monDom5"
    ]
  },
  "chicken": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Chicken.png",
      "genomes": [
      "galGal6",
      "galGal5"
    ]
  },
  "zebrafish": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Zebrafish.png",
      "genomes": [
      "danRer11",
      "danRer10",
      "danRer7"
    ]
  },
  "spotted Gar": {
    "image": "https://vizhub.wustl.edu/public/lepOcu1/SpottedGar.png",
      "genomes": [
      "lepOcu1"
    ]
  },
  "fruit fly": {
    "image": "https://epigenomegateway.wustl.edu/legacy/images/Fruit%20fly.png",
      "genomes": [
      "dm6"
    ]
  },
  "c.elegans": {
    "image": "https://epigenomegateway.wustl.edu/legacy/images/C.elegans.png",
      "genomes": [
      "ce11"
    ]
  },
  "arabidopsis": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Arabidopsis.png",
      "genomes": [
      "araTha1"
    ]
  },
  "seahare": {
    "image": "https://vizhub.wustl.edu/public/aplCal3/seaHare.png",
      "genomes": [
      "aplCal3"
    ]
  },
  "yeast": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Yeast.png",
      "genomes": [
      "sacCer3"
    ]
  },
  "P. falciparum": {
    "image": "https://epigenomegateway.wustl.edu/browser/images/Pfalciparum.png",
      "genomes": [
      "Pfal3D7"
    ]
  },
  "Green algae": {
    "image": "https://vizhub.wustl.edu/public/Creinhardtii506/Creinhardtii506.png",
      "genomes": [
      "Creinhardtii5.6"
    ]
  },
  "virus": {
    "image": "https://vizhub.wustl.edu/public/virus/virus.png",
      "genomes": [
      "SARS-CoV-2",
      "MERS",
      "SARS",
      "Ebola",
      "hpv16"
    ]
  },
  "trypanosome": {
    "image": "https://vizhub.wustl.edu/public/trypanosome/trypanosome.png",
      "genomes": [
      "TbruceiTREU927",
      "TbruceiLister427"
    ]
  }
}

export default exports;
