<template>
  <v-navigation-drawer v-model="drawer" app :expand-on-hover="$vuetify.breakpoint.mdAndDown" permanent dark
    color="#7a1705" class="elevation-8">
    <v-list>
      <v-list-item @click="navChange(user_items[2])" to="about">
        <v-list-item-action>
          <img src="../assets/cmg_logo_small.png" alt="CMG-SCA LOGO" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="title"> CMG-SCA </v-list-item-title>
          <v-list-item-subtitle v-if="$store.state.auth.uid">
            <div class="caption">Logged in as {{ $store.state.auth.uid }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-5"></v-divider>

      <v-list-item v-for="item in user_items" :value="item.active" :key="item.title" :to="item.path">
        <v-list-item-action>
          <v-icon>{{ item.action }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <span v-if="$store.getters.hasRole('admin')">
        <v-divider class="mb-5"></v-divider>
        <v-list-item v-for="item in admin_items_filtered" :value="item.active" :key="item.title" :to="item.path">
          <v-list-item-action>
            <v-icon>{{ item.action }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>

      <v-divider class="mb-10"></v-divider>

      <v-list-item v-for="item in bottom_items" :value="item.active" :key="item.title" :to="item.path">
        <v-list-item-action>
          <v-icon>{{ item.action }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'Drawer',
  data() {
    return {
      drawer: null,
      dialog: false,
      admin_items: [
        {
          action: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          path: '/dashboard',
        },
        {
          action: 'mdi-dna',
          title: 'Sequencing Runs',
          path: '/runs',
        },
        {
          action: 'mdi-file-lock',
          title: 'Data Products',
          path: '/dataproducts',
        },
        {
          action: 'mdi-transition',
          title: 'Conversions',
          path: '/conversions',
        },
        {
          action: 'mdi-folder-upload',
          title: 'Data Uploader',
          path: '/datauploader',
        },
        {
          action: 'mdi-table-account',
          title: 'User Management',
          path: '/users',
        },
        {
          action: 'mdi-account-multiple',
          title: 'Group Management',
          path: '/groups',
        },
        // {
        //   action: 'mdi-delete-empty-outline',
        //   title: 'Data Cleanup',
        //   path: '/clean',
        // },
        {
          action: 'mdi-format-list-bulleted',
          title: 'Stats/Tracking',
          path: '/stats',
        },
      ],
      user_items: [
        {
          action: 'mdi-flask',
          title: 'Projects',
          path: '/projects',
        },
        {
          action: 'mdi-chart-gantt',
          title: 'Tracks',
          path: '/tracks',
        },
        {
          action: 'mdi-chart-timeline',
          title: 'Sessions',
          path: '/sessions',
        },
      ],
      bottom_items: [
        {
          action: 'mdi-information',
          title: 'About',
          path: '/about',
        },
        {
          action: 'mdi-account-details',
          title: 'Profile',
          path: '/profile',
        },
        {
          action: 'mdi-logout-variant',
          title: 'Logout',
          path: '/signout',
        },
      ],
    }
  },
  computed: {
    admin_items_filtered() {
      if (this.$config.upload_enabled) return this.admin_items
      return this.admin_items.filter((ai) => {
        return ai.path !== 'upload'
      })
    },
  },
  methods: {
    navChange: function (navItem) {
      this.drawer = false
      this.$emit('navChange', navItem)
    },
    checkRoute: function () {
      // console.log('checking route')
      let currentPath = this.$router.currentRoute.path
      console.log(currentPath)
      console.log(currentPath === '/')
      // this.items.forEach(item => {
      //
      //     if(currentPath.includes(item.path)) this.navChange(item);
      // });

      this.admin_items.forEach((item) => {
        if (currentPath.includes(item.path)) this.navChange(item)
      })

      this.user_items.forEach((item) => {
        if (currentPath.includes(item.path)) this.navChange(item)
      })

      if (currentPath === '/') this.navChange(this.user_items[0])
    },
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.checkRoute()
    }, 200)
  },
  watch: {
    // $route(to, from) {
    $route() {
      // console.log("Drawer route to:", to)
      // console.log("Drawer route from:", from)
      this.checkRoute()
    },
  },
}
</script>

<style>
a {
  text-decoration: none !important;
}

.v-list {
  background-image: none !important;
}
</style>
