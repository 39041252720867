<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="6" md="4">
      <v-img src="../assets/cmg_logo.png" class="elevation-4 logo" />
      <v-divider class="mb-2 mt-2"></v-divider>

      <v-card class="my-2" v-show="unknownUser">
        <v-alert
          color="warning"
          dark
          icon="mdi-account-alert"
          border="top"
          prominent
        >
          No user account found. Access to the CMG portal is restricted to
          authorized users. If you feel this message is in error or would like
          to request access, please email "Hongyu Gao"
          <a href="mailto:hongao@iu.edu">hongao@iu.edu</a>, and CC
          sca-ops-l@iu.edu
        </v-alert>
      </v-card>

      <v-btn block @click="begin_iucas" class="elevation-8" color="#7a1705" dark
        ><v-img src="../assets/trident.png" max-width="15px" class="mx-1" />
        Login
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'signin',
  data() {
    return {
      unknownUser: false,
    }
  },
  computed: {
    casticket: function () {
      return this.$route.query.ticket
    },
    redirect: function () {
      return this.$route.query.redirect
    },
  },
  methods: {
    ...mapActions(['login']),

    begin_iucas: function () {
      window.location =
        this.$config.iucas_url + '?service=' + this.$config.cas_return
    },

    validate: function (casticket) {
      console.log(`Calling validate`)
      this.$http
        .get(
          this.$config.api +
            '/verify?casticket=' +
            casticket +
            '&service=' +
            this.$config.cas_return
        )
        .then(
          (res) => {
            console.log(res.data)
            this.completeLogin(res.data)
          },
          (err) => {
            this.errorLogin(err)
          }
        )
    },

    userLogin: function () {
      this.$http
        .post(`${this.$config.api}/userLogin`, { user: this.form })
        .then((res) => {
          this.completeLogin(res.data)
        }, this.errorLogin)
    },

    guestLogin: function () {
      this.$http.get(`${this.$config.api}/guestLogin`).then((res) => {
        this.completeLogin(res.data)
      }, this.errorLogin)
    },

    completeLogin: function (response) {
      this.login(response)
      this.$store.dispatch('snack', `Login successful`)
      console.log(this.$store.state.auth.jwt_exp)
      let destination = localStorage.getItem('redirect')
      if (destination) {
        console.log('redirect request found', destination)
        setTimeout(() => {
          this.$router.replace({ query: null })
          this.$router.push({ path: destination })
        }, 300)
      } else {
        setTimeout(() => {
          this.$router.replace({ query: null })
          this.$router.push({ path: '/projects' })
        }, 300)
      }
    },

    errorLogin: function (err) {
      let status = err.response.status
      if (status == 403) {
        this.$store.dispatch('snack', {
          msg: `Login failed: validation error`,
          isError: true,
          timeout: 5000,
        })
      }
      if (status == 401) {
        this.$store.dispatch('snack', {
          msg: `Login failed: unknown user`,
          isError: true,
          timeout: 5000,
        })
        this.unknownUser = true
      }
    },
  },
  mounted() {
    console.log(this.mode)
    if (this.redirect) {
      console.log('redirect is set, redirect is set to: ', this.redirect)
      localStorage.setItem('redirect', this.redirect)
    }

    if (this.casticket) {
      this.validate(this.casticket)
    }
  },
}
</script>

<style>
.logo {
  max-width: 600px;
}
</style>
