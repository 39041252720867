<template>
  <div>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        <v-icon class="mr-1 white--text">mdi-help-circle</v-icon>
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="yes"> Yes </v-btn>
        <v-btn color="secondary" text @click="no"> No </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// This version expects the parent to handle the actual dialog values
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },
    message: {
      type: String,
      default: 'Please confirm this action',
    },
  },

  data() {
    return {}
  },

  methods: {
    yes() {
      // this.dialog = false;
      // console.log("emit confirm");
      this.$emit('confirm')
    },
    no() {
      // this.dialog = false;
      // console.log("emit cancel");
      this.$emit('cancel')
    },
  },
  created() {
    // console.log("In Confirm Dialog Create");
  },
}
</script>
