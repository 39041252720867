<template>
  <v-app>
    <Snackbar></Snackbar>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
import AppLayout from './layouts/AppLayout'
import HeaderLayout from './layouts/HeaderLayout'
import SimpleLayout from './layouts/SimpleLayout'
import DetailLayout from './layouts/DetailLayout'
import Snackbar from './components/Snackbar'
export default {
  computed: {
    layout() {
      return this.$store.getters.layout
    },
  },
  components: {
    'app-layout': AppLayout,
    'simple-layout': SimpleLayout,
    'detail-layout': DetailLayout,
    'header-layout': HeaderLayout,
    Snackbar,
    // define as many layouts you want for the application
  },
  methods: {},
  created() {
    document.title = 'CMG-SCA: Medical Genomics Portal'
  },
}
</script>

<style>
body {
  -webkit-font-smoothing: subpixel-antialiased;
  background: url('./assets/so-white.png');
}

.clickable {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.main {
  min-height: 80vh;
  height: auto;
}
</style>
